import { useTranslation } from '@pancakeswap/localization'
import { AutoRenewIcon, Button, Flex, Grid, Text } from '@pancakeswap/uikit'
import { useCallback } from 'react'
import { Collection } from 'state/nftMarket/types'
import { CollectibleLinkCard } from '../../components/CollectibleCard'
import GridPlaceholder from '../../components/GridPlaceholder'
import { useCollectionNfts } from '../../hooks/useCollectionNfts'
import NoNftImageDark from '../../components/BannerHeader/NoNftImageDark'

interface CollectionNftsProps {
  collection?: Collection
}

const CollectionNfts: React.FC<React.PropsWithChildren<CollectionNftsProps>> = ({ collection }) => {
  const { address: collectionAddress } = collection || {}
  const { t } = useTranslation()
  const { nfts, isFetchingNfts, fetchNextPage, resultSize, isLastPage } = useCollectionNfts(collectionAddress)
  const noNftImage = '/images/nfts/no-profile-md-dark.png'
  const handleLoadMore = useCallback(() => {
    fetchNextPage()
  }, [fetchNextPage])

  if ((!nfts || nfts?.length === 0) && isFetchingNfts) {
    return <GridPlaceholder />
  }

  return (
    <>
      {resultSize && (
        <Flex p="16px">
          <Text bold>
            {resultSize} {t('Results')}
          </Text>
        </Flex>
      )}
      {nfts.length > 0 ? (
        <>
          <Grid
            gridGap="16px"
            gridTemplateColumns={['1fr', null, 'repeat(3, 1fr)', null, 'repeat(4, 1fr)']}
            alignItems="start"
          >
            {nfts.map((nft) => {
              const currentAskPriceAsNumber = nft.marketData && parseFloat(nft?.marketData?.currentAskPrice)
              return (
                <CollectibleLinkCard
                  key={`${nft.tokenId}-${nft.realTokenId}`}
                  nft={nft}
                  currentAskPrice={
                    currentAskPriceAsNumber && currentAskPriceAsNumber > 0 ? currentAskPriceAsNumber : undefined
                  }
                  data-test="collection-detail-page-card"
                />
              )
            })}
          </Grid>
          <Flex mt="60px" mb="12px" justifyContent="center">
            {!isLastPage && (
              <Button
                onClick={handleLoadMore}
                scale="sm"
                disabled={isFetchingNfts}
                endIcon={isFetchingNfts ? <AutoRenewIcon spin color="currentColor" /> : undefined}
              >
                {isFetchingNfts ? t('Loading') : t('Load more')}
              </Button>
            )}
          </Flex>
        </>
      ) : (
        <Flex alignItems="center" py="48px" flexDirection="column">
          <NoNftImageDark src={noNftImage} alt={t('No Squirrel Found')} />
          <Text fontWeight={600}>{t('No squirrels found')}</Text>
        </Flex>
      )}
    </>
  )
}

export default CollectionNfts
