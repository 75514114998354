import { styled } from 'styled-components'

interface NoNftImageDarkProps {
  src: string
  bordercolor?: string
  alt?: string
}

const NoNftImageDark = styled.div.attrs<NoNftImageDarkProps>(({ alt }) => ({
  alt,
}))<NoNftImageDarkProps>`
  background: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  position: relative;
  width: 96px;
  height: 96px;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px ${({ bordercolor }) => bordercolor || '#f2ecf2'} dashed;

  & > img {
    border-radius: 50%;
  }
`

export default NoNftImageDark
